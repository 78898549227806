.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.popup-container {
    background: white;
    color: var(--dark-green);
    border-radius: 15px;
    width: 80%;
    max-width: 500px;
    box-shadow: 5px 5px 15px rgba(26, 26, 26, 0.288);
}
  
.popup-header {
    font-family: 'Righteous', cursive;
    font-size: 1.7em;
    color: var(--dark-green);
    text-align: center;
    padding: 20px 30px 10px 30px;
}
  
.popup-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 0px 30px 10px 30px;
}
  
.content-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.content-section h3 {
    margin: 10;
    color: var(--light-green);
}

.content-section input[type="checkbox"] {
    margin-right: 10px;
    accent-color: var(--light-green);
  }

.content-section label {
    margin-bottom: 5px;
    font-size: 1.1rem;
}
  
.popup-footer {
    display: flex;
    justify-content: end;
    background-color: var(--light-green);
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding: 8px 0;
}
  
.popup-footer button {
    font-family: 'Roboto', sans-serif;
    margin: 5px 20px 5px 10px;
    font-size: 1.2rem;
    font-weight: 500;
    background-color: white;
    color: var(--light-green);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 10px 25px;
    transition: background-color 0.3s;
}

.popup-footer button:hover {
    background-color: var(--yellow-green);
    color: white;
}
  