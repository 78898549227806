@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

:root {
  --dark-green: #25430D;
  --light-green: #5DA921;
  --pale-green: #BDE99B;
  --yellow-green: #C0C323;
  --main-bg: #fff;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-header {
  padding: 2px;
  text-align: left;
  height: 3vh;
  display: flex;
  align-items: center;
}

.timer-title {
  font-family: 'Righteous', cursive;
  margin-right: 10px;
  margin-left: 40px;
  font-size: 1.3em;
  color: var(--dark-green);
}

.app-logo {
  height: 60%;
  vertical-align: middle;
}

.hero-banner-background {
  background: linear-gradient(to right, var(--dark-green) 0%, var(--dark-green) 30%, var(--light-green) 100%); 
  padding: 10px;
  height: 40%;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Split into two horizontal halves */
  text-align: center;
  border-bottom: 1px solid var(--border-color);
  background: linear-gradient(to right, var(--dark-green) 0%, var(--dark-green) 30%, var(--light-green) 100%);
  height: 40%;
  min-height: 40vh;
}

.left-half {
  display: grid;
  grid-template-rows: 1fr 1fr; /* Split left half into two vertical halves */
  padding-left: 5%;
  padding-right: 5%;
}

.top-left {
  color: white;
  padding: 20px 33% 20px 30px;
  box-sizing: border-box;
  display: flex; /* Use flexbox to align the text */
  flex-direction: column; /* Stack children vertically */
  justify-content: center;
}

.introduction-header{
  font-family: 'Righteous', cursive;
  font-size: 3.5rem;
  font-weight: 500;
  margin: 0 0 10px 0;
  align-self: flex-start;
}

.introduction-text{
  font-size: 2rem;
  font-weight: 300;
  margin: 0;
  align-self: flex-start;
  text-align: left;
}

.bottom-left {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Further split the bottom left into two horizontal halves */
}

.bottom-left-top {
  background-color: #bbb; /* Darker gray background for visibility */
}

.bottom-left-bottom {
  background-color: #999; /* Even darker gray background for visibility */
}

.right-half {
  align-items: center;
  display: flex;
  justify-content: center;
}

.screenshot-image {
  width: 40rem;
  height: 25rem;
  object-fit: cover;
  box-shadow: #25430D 0px 0px 10px 0px;
}

.bottom-left-section {
  display: flex;
  align-items: flex-start;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.section-image-container {
  margin-right: 20px; /* Adjust spacing as needed */
  flex-shrink: 0; 
}

.section-image {
  width: 3em; /* Adjust size as needed */
  height: 3em; /* Adjust size as needed */
  border-radius: 50%; /* Makes the image round */
  object-fit: cover;
}

.section-text-container {
  flex-grow: 1;
}

.section-text {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: left;
}

.mailing-list {
  flex-grow: 1; /* Takes up the remaining space */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the items horizontally */
  justify-content: center; /* Center the items vertically if there's enough space */
  padding: 20px; /* Add padding as needed */
  margin-top: 4%;
}

.launch-title {
  font-size: 6em; /* Adjust the font size as needed */
  font-weight: 500;
  color: var(--dark-green); /* Use the dark green color */
  margin: 0 0 20px 0; /* Add bottom margin for spacing between the title and the input */
  text-align: center;
}

.email-signup {
  display: flex;
  gap: 10px; /* Adjust the gap between the input and the button */
  width: 40%;
  max-width: 800px;
  min-width: 600px;
}

.email-input {
  font-size: 1.2em; /* Match font size for accessibility */
  padding: 10px;
  border: 1px solid #cccccc; /* A light grey border */
  flex-grow: 1; /* Make the input grow to use available space */
  border-radius: 5px;
}

.valid-input {
  border: 1px solid #cccccc;
  color: black;
  background-color: white;
}

.invalid-input {
  border: 1px solid rgb(214, 3, 3);
  color: rgb(214, 3, 3);
  background-color: rgb(239, 191, 191);
}

.waitlist-btn {
  font-size: 1.4em;
  background-color: var(--yellow-green); /* Light green background for the button */
  color: white;
  border: none;
  padding: 10px 40px; /* Padding inside the button */
  cursor: pointer; /* Changes the cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  border-radius: 5px;
}

.waitlist-btn:hover {
  background-color: var(--light-green); /* Darken the button when hovering */
}
