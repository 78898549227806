.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.confirmation-container {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}
  
.confirmation-container p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}
  
.confirmation-container button {
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    background-color: var(--yellow-green);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.confirmation-container button:hover {
    background-color: var(--light-green);
}
  